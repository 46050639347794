import { cn } from "@/lib/utils";
import { TCountryCode } from "countries-list";
import { hasFlag } from "country-flag-icons";
import * as allFlags from "country-flag-icons/react/3x2";
import { FC } from "react";

type CountryFlagProps = {
  code: TCountryCode | string | undefined | null;
  className?: string;
};

const CountryFlag: FC<CountryFlagProps> = ({ code, className }) => {
  if (!code) return null;
  const hasFlagIcon = hasFlag(code);
  const Flag = !hasFlag ? null : (allFlags as any)[code];
  if (!hasFlagIcon || !Flag) return null;
  return <Flag className={cn(className ?? "size-4")} />;
};

export default CountryFlag;
