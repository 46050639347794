import { cn } from "@/lib/utils";
import { FC, ForwardRefExoticComponent, ReactNode } from "react";
import { Button } from "./ui/button";

export type StateProps = {
  icon: ForwardRefExoticComponent<any>;
  iconClassName?: string;
  title?: string;
  description?: ReactNode;
  action?: {
    text: string;
    onClick: () => void;
  };
  className?: string;
};

const State: FC<StateProps> = ({
  title,
  description,
  action,
  icon,
  iconClassName,
  className,
}) => {
  const Icon = icon;
  return (
    <div
      className={cn(
        "flex flex-col space-y-2 items-center justify-center p-4 max-w-sm mx-auto h-full text-center grow whitespace-pre-wrap",
        className
      )}
    >
      <Icon className={cn("size-24 text-gray-400", iconClassName)} />
      {title && <span className="font-bold">{title}</span>}
      {description && <p>{description}</p>}
      {action && (
        <Button type="button" size="lg" onClick={action.onClick}>
          {action.text}
        </Button>
      )}
    </div>
  );
};

export default State;
